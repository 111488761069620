.app-wrapper {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.header-wrapper,
.footer-wrapper {
  height: 10vh;
  width: 100vw;
  margin: 0;
  padding: 0;
}
.main-wrapper {
  display: flex;
  height: 80vh;
  width: 100vw;
}
.sidebar-wrapper {
  height: 100%;
  width: 20vw;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
}
.content-wrapper {
  height: 100%;
  width: 80vw;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #f0f0f0;
}
.content-card {
  color: #000000;
  background-color: #ffffff;
  margin: 1.2rem !important;
}

.ant-table.ant-table-bordered > .ant-table-title {
  border: none !important;
}
.tabs-container {
  margin: 1.2rem;
}
.ant-tabs-top > .ant-tabs-nav {
  margin: 0;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  background: #f0f0f0;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
  background: #ffffff;
  border-color: #ffffff;
}

.table-view {
  background-color: #ffffff;
  padding: 24px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.table-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  gap: 12px;
}
.filters-container {
  flex: 1;
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
}
.table-highlighted-row {
  background: linear-gradient(
    to left,
    #f06d06,
    rgba(240, 109, 6, 0.1),
    rgba(240, 109, 6, 0.1)
  );
}
.table-highlighted-row:hover .ant-table-cell {
  background: rgba(240, 109, 6, 0.1) !important;
}

.header-container {
  padding: 1.2% 2%;
  border-bottom: solid 1px #e8e8e8;
  box-shadow: 0 0 15px #afadad;
  background-color: rgb(12, 1, 37);
  height: 100%;
  width: 100%;
  z-index: 2;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-logo {
  height: 100%;
  margin-right: 3rem;
  display: inline-block;
}
.header-logo img {
  height: 100%;
  object-fit: contain;
}
.header-logout {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #fff;
  margin-right: 2rem;
}

.footer-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(12, 1, 37);
  z-index: 2;
}

.form-wrapper {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 300px;
  max-width: 100vw;
  min-height: 75vh;
  margin: 5vh auto;
}
.textarea-view .ant-form-item-label > label {
  height: 20px !important;
}
.form-container .ant-form-item-label > label {
  height: 24px !important;
}

.adbuck-image-preview {
  position: relative;
  padding: 0 10px;
}
.adbuck-image-main {
  width: 100%;
  aspect-ratio: 0.5;
  position: relative;
  overflow: hidden;
}
.adbuck-image-main .ant-image,
.adbuck-image-main .ant-image-img {
  width: 100%;
  height: 100%;
}

.adbuck-denomination-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.ant-image-preview-img-wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

div[class^="adbuck-overlay"] .ant-image-preview-img,
div[class*=" adbuck-overlay"] .ant-image-preview-img {
  height: 100%;
  object-fit: cover;
  aspect-ratio: 0.5;
}

.inner_html_content img,
.inner_html_content iframe,
.inner_html_content video {
  max-width: 100%;
  height: auto;
}
